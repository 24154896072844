<template>
  <div>
    <el-card>
      <common-user-center-title :title="'订单详情'"></common-user-center-title>
      <div class="mx-1">
        <div class="py-3 font-s-2 text-main-color border-bottom clearfix">
          <div class="f-l">
            <div class="ml-2" v-if="orderInfo.statusCode === 'paid'">未付款</div>
            <div class="ml-2" v-else-if="orderInfo.statusCode === 'delivery'">待发货</div>
            <div class="ml-2" v-else-if="orderInfo.statusCode === 'delivered'">已发货</div>
            <div class="ml-2" v-else-if="orderInfo.statusCode === 'received'">已收货</div>
            <div class="mt-1 ml-2">订单号：{{orderInfo.no}}</div>
          </div>
          <el-button class="f-r" @click="logisticsDialogVisible = true" type="success" size="mini">物流信息</el-button>
        </div>
        <div class="py-3 border-bottom">
          <div class="df-ac border-bottom py-1 mx-1" v-for="(item,index) in orderInfo.items" :key="index">
            <div class="flex-1 d-f ">
              <img v-lazy="item.product_img" class="ml-2" style="width: 90px;height: 90px;"/>
              <div class="ml-2 font-s-2 text-grey">
                <div>{{item.product_name}}</div>
                <div>规格：{{item.sku}}</div>
                <div>单价：￥{{item.price}}</div>
                <div>数量：{{item.amount}}</div>
              </div>
            </div>
          </div>
          <div class="py-2 mx-1 font-s-2 text-grey d-f justify-end">
            <div class="ml-3">商品总价：<span class="ml">￥{{orderInfo.realTotalAmount}}</span></div>
            <div class="ml-5">快递费：<span class="ml">￥0</span></div>
            <div class="ml-5">优惠券：<span class="ml">￥0</span></div>
            <div class="ml-5">应付款：<span class="ml">￥{{orderInfo.realTotalAmount}}</span></div>
          </div>
        </div>
        <div class="py-3 border-bottom">
          <div class="font-s-3">收货人信息</div>
          <div class="font-s-2 mt-2 text-grey">
            <div class="mt-1">收货人:<span class="ml-3">{{orderInfo.contactName}}</span></div>
            <div class="mt-1">手机号码:<span class="ml-3">{{orderInfo.contactPhone}}</span></div>
            <div class="mt-1">收货地址:<span class="ml-3">{{orderInfo.province}}{{orderInfo.city}}{{orderInfo.district}}{{orderInfo.address}}</span></div>
          </div>
        </div>
        <div class="py-3 border-bottom">
          <div class="font-s-3">付款信息</div>
          <div class="font-s-2 mt-2 text-grey">
            <div class="mt-1">付款状态:
              <span class="ml-3" v-if="orderInfo.statusCode === 'paid'">未付款</span>
              <span class="ml-3" v-else-if="orderInfo.statusCode === 'delivery'">待发货</span>
              <span class="ml-3" v-else-if="orderInfo.statusCode === 'delivered'">已发货</span>
              <span class="ml-3" v-else-if="orderInfo.statusCode === 'received'">已收货</span>
            </div>
            <div class="mt-1">付款方式:<span class="ml-3">无</span></div>
            <div class="mt-1">付款时间:<span class="ml-3">无</span></div>
          </div>
        </div>
        <div class="py-3 border-bottom" v-if="orderInfo.expressNo">
          <div class="font-s-3">配送信息</div>
          <div class="font-s-2 mt-2 text-grey">
            <div class="mt-1">配送方式:<span class="ml-3">{{orderInfo.expressName}}</span></div>
            <div class="mt-1">快递单号:<span class="ml-3">{{orderInfo.expressNo}}</span></div>
            <div class="mt-1">配送状态:<span class="ml-3">无</span></div>
            <div class="mt-1">发货时间:<span class="ml-3">{{orderInfo.deliveryAt}}</span></div>
          </div>
        </div>
      </div>
      <el-dialog title="物流信息"
                 :visible.sync="logisticsDialogVisible" width="50%">
        <div v-if="logisticsInfo.list.length==0">暂无物流信息</div>
        <el-timeline :reverse="true" v-else>
          <el-timeline-item
            v-for="(item, index) in logisticsInfo.list"
            :key="index"
            :timestamp="item.time">
            {{item.status}}
          </el-timeline-item>
        </el-timeline>
        <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="logisticsDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="logisticsDialogVisible = false">确 定</el-button>
                  </span>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import { getOrderLogisticsUrl, getOrderDetailUrl } from '@/api/index'

export default {
  components: {
    commonUserCenterTitle
  },
  name: 'EstablishOrder',
  created () {
    this.initData()
  },
  data () {
    return {
      orderId: '',
      orderInfo: {},
      logisticsInfo:{
        list:[],
       /* "number": "550014018689450",
        "type": "HTKY",
        "list": [
          {
            "time": "2021-05-18 10:04:11",
            "status": "【广州海珠区十四部】，以签收 已签收"
          },
          {
            "time": "2021-05-17 13:57:40",
            "status": "【陈佳林/13640825404】正在派件，【广州海珠区十四部/网点电话19926092847 】"
          },
          {
            "time": "2021-05-17 07:13:42",
            "status": "到【广州海珠区十四部】"
          },
          {
            "time": "2021-05-16 20:04:04",
            "status": "【广州番禺转运中心】，正发往【广州海珠区十四部】"
          },
          {
            "time": "2021-05-16 19:46:21",
            "status": "到【广州番禺转运中心】"
          },
          {
            "time": "2021-05-15 20:23:36",
            "status": "【南昌转运中心】，正发往【广州番禺转运中心】"
          },
          {
            "time": "2021-05-15 20:20:58",
            "status": "到【南昌转运中心】"
          },
          {
            "time": "2021-05-15 20:09:44",
            "status": "到【南昌2分拨集货点】"
          },
          {
            "time": "2021-05-15 18:54:33",
            "status": "【孺子路分部】，【熊裕超/15079856316】已揽收"
          }
        ],
        "deliverystatus": "3",
        "issign": "1",
        "expName": "百世快递",
        "expSite": "www.htky365.com ",
        "expPhone": "95320",
        "logo": "https://img3.fegine.com/express/htky.jpg",
        "courier": "",
        "courierPhone": "",
        "updateTime": "2021-05-18 10:04:11",
        "takeTime": "2天15小时9分"*/
      },
      logisticsDialogVisible:false
    }
  },
  methods: {
    initData () {
      if (this.$route.query.orderId == undefined) {
        return this.$message.error('订单错误')
      }
      if (this.$route.query.orderNo == undefined) {
        return this.$message.error('订单错误')
      }
      this.orderId = this.$route.query.orderId
      this.orderInfo.no = this.$route.query.orderNo
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.getOrderInfo()
      this.getOrderLogistics()
      setTimeout(()=>{
        this.changeLoading(loading)
      },300)
    },
    // 关闭loading弹窗
    changeLoading(loading){
      loading.close();
    },
    // 获取订单详情
    async getOrderInfo () {
      const { data: res } = await this.$http.get(getOrderDetailUrl + this.orderId)
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.orderInfo = res.data
      this.orderInfo.items.forEach(item=>{
        item.sku=[]
        for(let i in item.productSkuNames){
          item.sku.push(item.productSkuNames[i])
        }
        item.sku = item.sku.join('，')
      })
      console.log(this.orderInfo)
    },
    // 获取订单物流轨迹
    async getOrderLogistics () {
      const { data: res } = await this.$http.get(getOrderLogisticsUrl, { params: { orderSn: this.orderInfo.no } })
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      if(res.data.list.length>0){
        this.logisticsInfo = res.data
      }
    },
  }

}
</script>

<style lang="less" scoped>

</style>

