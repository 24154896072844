import { render, staticRenderFns } from "./EstablishOrder.vue?vue&type=template&id=43c70539&scoped=true&"
import script from "./EstablishOrder.vue?vue&type=script&lang=js&"
export * from "./EstablishOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43c70539",
  null
  
)

export default component.exports